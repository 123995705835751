<template>
    <div class="form-2">
        <div class="inputs">
            <div class="title text-black font-bold font-roboto text-base">{{title[lang]}}</div>
            <div class="form-group my-2">
                <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputWeight.label[lang]}}</div>
                <input v-model="form.weight" @focus="errorWeight=''" type="number" step="any" class="w-3/4 bg-gray-custom-300 border pl-2 border-blue-custom rounded-lg" :placeholder="inputWeight.placeHolder[lang]" name="weight">
                <div class="error pl-1 text-xs text-red-500" v-text="errorWeight"></div>
            </div>
            <div class="form-group my-2">
                <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputHeight.label[lang]}}</div>
                <input v-model="form.height" @focus="errorHeigth = ''" type="number" step="any" class="w-3/4 bg-gray-custom-300 border pl-2 border-blue-custom rounded-lg" :placeholder="inputHeight.placeHolder[lang]" name="height">
                <div class="error pl-1 text-xs text-red-500" v-text="errorHeigth"></div>
            </div>
            <div class="form-group my-2">
                <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputTotal.label[lang]}}</div>
                <div class="flex flex-col sm:flex-row">
                    <input type="text" v-model="form.total" class="w-3/4 bg-gray-custom-400 border pl-2 border-blue-custom rounded-lg" readonly name="height">
                    <div class="flex justify-end mt-1 sm:mt-0 sm:w-1/4">
                        <button
                            class=" text-white bg-blue-custom py-1 px-5 font-roboto rounded-md text-sm"
                            type="button"
                            @click="()=>{ lang == 'en'?calculateStandar():calculateMetric()}"
                        >{{buttonCalulate[lang]}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lang:document.documentElement.lang,
            form:{
                weight:"",
                height:"",
                total:""
            },
            title:{
                "es":"Calculador IMC",
                "en":"BMI CALCULATOR"
            },
            inputWeight:{
                label:{
                    "es":"Peso",
                    "en":"Weight"
                },
                placeHolder:{
                    "es":"Ingresa tu peso en Kg.",
                    "en":"Enter your weight in Lbs."
                },
                errorMessage:{
                    "es":"Favor de ingresar su peso",
                    "en":"Please enter your weight"
                }
            },
            inputHeight:{
                label:{
                    "es":"Altura",
                    "en":"Height"
                },
                placeHolder:{
                    "es":"Ingresa tu Altura en Mts.",
                    "en":"Enter your Height in Inches."
                },
                errorMessage:{
                    "es":"Favor de ingresar su altura",
                    "en":"Please enter your height"
                }
            },
            inputTotal:{
                label:{
                    "es":"Su IMC",
                    "en":"Your BMI"
                }
            },
            buttonCalulate:{
                "es":"Calcular",
                'en':"Calculate"
            },
            errorWeight:"",
            errorHeigth:""
        }
    },
    methods: {
        calculateStandar: function(){
            if(!this.validateForm())
              return
            const pounds = this.form.weight * 703;
            const inches = this.form.height * this.form.height;
            const total  = pounds/inches;

            this.form.total = total.toFixed(2);
        },
        calculateMetric:function(){
            if(!this.validateForm())
              return
            const height = this.form.height * this.form.height;
            const weight = this.form.weight;
            const total  = weight/height;

            this.form.total = total.toFixed(2);
        },
        validateForm:function (){
            let valid = true;
            if(this.form.height == ""){
                valid = false;
                this.errorHeigth = this.inputHeight.errorMessage[this.lang];
            }

            if(this.form.weight == ""){
                valid = false;
                this.errorWeight = this.inputWeight.errorMessage[this.lang];
            }

            return valid;

        },
        clearForm:function(){
            this.form.weight = "";
            this.form.height = "";
            this.form.total = "";
        }
    },
}
</script>
<style scoped>
 /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
