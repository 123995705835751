const Schedule = {

    startSchedule:{
        hour:10,
        minutes:0
    },
    endSchedule:{
        hour:17,
        minutes:30
    },
    googleEvents:[],
    schedules:[],

    setStartSchedule:function(payload){
        const {hour,minutes} = payload;
        this.startSchedule.hour = hour;
        this.startSchedule.minutes = minutes;
    },
    setEndSchedule:function(payload){
        const {hour,minutes} = payload;
        this.endSchedule.hour = hour;
        this.endSchedule.minutes = minutes
    },
    addSchedules:function(payload){
        const {hour,minutes,date,available = true,selected = false} = payload;
        const schedule = {
            time:`${hour}:${minutes < 10?'0'+minutes:minutes}`,
            hour,
            minutes,
            available,
            date,
            selected
         }
        if(this.googleEvents)
          this.checkGoogleEvent(schedule);
        this.schedules.push(schedule);
    },
    setGoogleEvents:function(events){
        this.googleEvents = [];
        this.googleEvents = events;
    },
    checkGoogleEvent:function(schedule){
        const startTimeSchedule = schedule.date;
        startTimeSchedule.setHours(schedule.hour,schedule.minutes,0);

        let startEvent;
        let endEvent;
        const event = this.googleEvents.filter( event =>{
            startEvent = new Date(event.start_date.format);
            endEvent   = new Date(event.end_date.format);
            return  ( startEvent <= startTimeSchedule && endEvent >= startTimeSchedule);
        });

        schedule.available = event.length > 0 ? false:true;
    },

    createSchedule:function(currentDay,date){
        this.schedules = [];
        let startHour = {
            hour:this.startSchedule.hour,
            minutes:this.startSchedule.minutes
        }
        let endHour = {
            hour:this.endSchedule.hour,
            minutes:this.endSchedule.minutes
        }
        if(currentDay){
            const currentTime   = new Date();
            const currentHour   = currentTime.getHours();
            const currentMinuts = currentTime.getMinutes();

            const timeStart = new Date();
            timeStart.setHours(startHour.hour, startHour.minutes,0)
            if( timeStart < currentTime ){
                if(currentMinuts <=10 )
                    startHour= {hour:currentHour,minutes:30};
                else
                    startHour = {hour:currentHour+1,minutes:currentMinuts >= 40? 30: 0};


            }
        }

        const startTime = new Date();
        const endTime   = new Date();
        startTime.setHours(startHour.hour,startHour.minutes,0);
        endTime.setHours(endHour.hour,endHour.minutes,0);
        if( startTime <= endTime ){

            this.addSchedules({hour:startHour.hour,minutes:startHour.minutes,date});
            if(startHour.minutes == 0 )
            this.addSchedules({hour:startHour.hour,minutes:30,date});

            for(let i = startHour.hour+1; i < endHour.hour; i++){
            this.addSchedules({hour:i,minutes:0,date});
            this.addSchedules({hour:i,minutes:30,date});
            }

            if( startHour.hour != endHour.hour){
                this.addSchedules({hour:endHour.hour,minutes:0,date});
                if(endHour.minutes == 30,date)
                  this.addSchedules({hour:endHour.hour,minutes:30,date});
            }

        }
    },
    getSchedules:function(){
        return this.schedules;
    }
}

export default Schedule;
