import axios from "axios";
import Schedule from "./Schedule";

export default{
    namespaced:true,
    state:{
        days:[],
        schedules:[],
        dayMilseconds : 1000*60*60*24,
        currentDay: new Date(),
        nameDays: [
            {"es":"Dom","en":"Sun"},
            {"es":"Lun","en":"Mon"},
            {"es":"Mar","en":"Tue"},
            {"es":"Mie","en":"Wed"},
            {"es":"Jue","en":"Thu"},
            {"es":"Vie","en":"Fri"},
            {"es":"Sab","en":"Sat"}
        ],
        nameMonths: [
            {"es":"Ene","en":"Jan"},
            {"es":"Feb","en":"Feb"},
            {"es":"Mar","en":"Mar"},
            {"es":"Abr","en":"Apr"},
            {"es":"May","en":"May"},
            {"es":"Jun","en":"Jun"},
            {"es":"Jul","en":"Jul"},
            {"es":"Ago","en":"Aug"},
            {"es":"Sep","en":"Sep"},
            {"es":"Oct","en":"Oct"},
            {"es":"Nov","en":"Nov"},
            {"es":"Dic","en":"Dec"}
        ]

    },
    mutations:{
        addDay(state,payload){
            const {date,lang,currentDay,events} = payload;
            Schedule.setGoogleEvents(events);
            Schedule.createSchedule(currentDay,date);
            state.days.push({
                dayName:state.nameDays[date.getDay()][lang],
                monthName:state.nameMonths[date.getMonth()][lang],
                year:date.getFullYear(),
                dayNumber:date.getDate(),
                date:`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`,
                schedules:Schedule.getSchedules(),
                events:events,
                currentDay:currentDay
            })

        },
        clearDays(state){
            state.days = [];
        }
    },
    actions:{
        fillDays: async function({state,commit,dispatch},payload){
            const {numberDays,lang} = payload;
            commit('clearDays');
            let events = await dispatch('getEventsCalendar',state.currentDay);
            commit('addDay',{date:state.currentDay,lang:lang,currentDay:true,events:events});
            for( let i= 1; i < numberDays; i++){
                let sumDay = new Date(state.currentDay.getTime()+state.dayMilseconds*i);
                events = await dispatch('getEventsCalendar',sumDay);
                commit('addDay',{date:sumDay,lang:lang,currentDay:false,events:events});
            }

        },
        getEventsCalendar:async function({},date){
            const payload = {
                day:`${date.getFullYear()}-${date.getMonth()+1}-${date.getDate <10?'0'+date.getDate():date.getDate()}`
            }
            const response = axios.post('/get-events-calendar',payload)
            .then(response =>{
                return response.data.events;
            })
            .catch( error => {
                return null;
            });

            return response;
        }
    }
}
