<template>
    <div class="form bg-gray-custom-100 text-left border-blue-custom border rounded-3xl pt-7 pb-5 px-3">
            <form  v-on:submit.prevent="sendMessage" ref="form_contact">
                <div class="form-contact">
                    <div class="form-group my-2">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputName.label[lang]}}</div>
                        <input v-model="form.name" type="text" @focus="errorName = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2" name="name">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorName"></div>
                    </div>
                    <div class="form-group my-2">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputEmail.label[lang]}}</div>
                        <input v-model="form.email" type="email" @focus="errorEmail = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2" name="email">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorEmail"></div>
                    </div>
                    <div class="form-group my-2">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputPhone.label[lang]}}</div>
                        <input v-model="form.phone" type="text" @focus="errorPhone = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2" name="phone">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorPhone"></div>
                    </div>
                    <div class="form-group  mt-8 mb-5">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputMessage.label[lang]}}</div>
                        <textarea v-model="form.message" rows="5" @focus="errorMessage = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2" name="message"></textarea>
                        <div class="error pl-1 text-xs text-red-500" v-text="errorMessage"></div>
                        <div class="flex justify-between mt-6">
                            <button
                              :disabled="sending"
                              class=" text-white bg-blue-custom py-1 px-5 font-roboto rounded-md text-sm "
                              v-bind:class="{ 'opacity-60': sending }"
                            >
                                <svg v-if="sending" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white float-left" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <span v-text="sending?button.labelSending[lang]:button.labelStatic[lang]"></span>
                              </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
</template>
<script>
export default {
    data() {
        return {
            lang:document.documentElement.lang,
            form:{
                name:"",
                email:"",
                phone:"",
                message:""
            },
            inputName:{
                label:{
                    "es":"Nombre",
                    "en":"Name"
                },
                errorMessage:{
                    "es":"Favor de ingresar su nombre",
                    "en":"Please enter your name"
                }
            },
            inputEmail:{
                label:{
                    "es":"Correo",
                    "en":"Email"
                },
                errorMessage:{
                    "es":"Favor de ingresar su correo",
                    "en":"Please enter your email"
                },
                errorFormatMessage:{
                    "es":"Favor de ingresar un correo válido",
                    "en":"Please enter a valid email"
                }
            },
            inputPhone:{
                label:{
                    "es":"Teléfono",
                    "en":"Phone"
                },
                errorMessage:{
                    "es":"Favor de ingresar su teléfono",
                    "en":"Please enter your phone"
                }
            },
            inputMessage:{
                label:{
                    "es":"Cuéntanos tu caso",
                    "en":"Tell us about your case"
                },
                errorMessage:{
                    "es":"Favor de ingresar el mensaje",
                    "en":"Please enter the message"
                }
            },
            button:{
                labelStatic:{
                  "es":"Enviar",
                  'en':"Send"
                },
                labelSending:{
                  "es":"Enviando",
                  'en':"Sending"
                }

            },
            errorName:"",
            errorEmail:"",
            errorPhone:"",
            errorMessage:"",
            sending:false,
        }
    },
    methods:{
        sendMessage:function(e){
            const form = new FormData(this.$refs.form_contact);
            this.sending = true;
            const route = this.lang == 'en'?'/en/send-message':'/es/send-message';

            axios.post(route,form)
            .then( response =>{
                this.sending = false;
                this.clearForm();
                // this.$refs.calendar.updateCalendar();
                const url = window.location.origin + window.location.pathname;
                history.replaceState(null, "",url+'?form=contacto');
                this.$toast.open({
                    message:response.data.message,
                    type: "success",
                    duration: 5000,
                    position: "bottom",
                    dismissible: true
                });
            })
            .catch( error =>{
                if(error.response.status === 422 ){
                    const messages = error.response.data.errors;
                    this.errorName = messages.name? messages.name[0]:"";
                    this.errorEmail = messages.email? messages.email[0]:"";
                    this.errorPhone = messages.phone? messages.phone[0]:"";
                    this.errorMessage = messages.message? messages.message[0]:"";

                }else if(error.response.status === 401){
                    // this.$refs.calendar.openCalendar();
                    // this.$refs.calendar.updateCalendar();
                    this.$toast.open({
                        message:error.response.data.message,
                        type: "error",
                        duration: 7000,
                        position: "bottom",
                        dismissible: true
                    });
                }else{
                    this.$toast.open({
                        message:this.lang === 'en'?'An unexpected error occurred':'Ocurrió un error inesperado',
                        type: "error",
                        duration: 5000,
                        position: "bottom",
                        dismissible: true
                    });
                }

                this.sending = false;
            })
        },
        clearForm:function(){
            this.form.name    = "";
            this.form.email   = "";
            this.form.phone   = "";
            this.form.message = "";
        }
    }
}
</script>
