<template>
    <div
      :class="['day m-4 p-2 flex items-center justify-center',!hour.selected?'bg-opacity-10':'',hour.available?'cursor-pointer bg-blue-custom':' bg-gray-300 line-through']"
      @click="selectAppointment()"
    >
      {{hour.time}}
    </div>
</template>
<script>
export default {
    name:'Hour',
    props:['hour'],
    methods:{
        selectAppointment:function(){
            if( this.hour.available){
                this.$emit('selectAppointment',this.hour);
                this.hour.selected = true;
            }
        }
    }
}
</script>
