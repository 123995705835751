/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import Vuex from "vuex";

window.Vue = require('vue').default;
Vue.use(VueToast);


Vue.component('form-contact',require('./components/FormContact.vue').default);
Vue.component('form-candidate',require('./components/CandidateForm.vue').default);
Vue.component('form-home', require('./components/FormHome.vue').default);

//import modules

import day from "./modules/day";
const store = new Vuex.Store({
    modules:{
        day
    }
});
const app = new Vue({
    el: '#app',
    store:store,
    data() {
        return {
            lang:document.documentElement.lang
        }
    },
});
