<template>
    <div class="form bg-gray-custom-100 text-left border-blue-custom border rounded-3xl pt-7 pb-5 px-3">
            <form  v-on:submit.prevent="sendMessage" ref="form_candidate">
                <input type="hidden" v-model="lang" name="lang">
                <div class="form-contact">
                    <div class="form-group form-m-y">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold mb-2">1.-{{inputWeight.label[lang]}}</div>
                        <input v-model="form.weight" type="number" step="any" @focus="errorWeight = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2 md:w-1/4" name="weight">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorWeight"></div>
                    </div>
                    <div class="form-group form-m-y">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold mb-2">2.-{{inputHeight.label[lang]}}</div>
                        <input v-model="form.height" type="number" step="any" @focus="errorHeight = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2 md:w-1/4" name="height">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorHeight"></div>
                    </div>
                    <div class="form-group form-m-y" v-if="bmi">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold mb-2">{{inputOverweight.label[lang]}}</div>
                          <div class="pl-2">{{bmi}}</div>
                          <input type="hidden" v-model="bmi" name="bmi">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorOverweight"></div>
                    </div>
                    <div class="form-group form-m-y">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold mb-2">3.-{{inputMedicalConditions.label[lang]}}</div>
                        <div class=" grid grid-cols-1 gap-2 pl-4 justify-items-start text-blue-custom text-xs font-semibold sm:grid-cols-2 md:grid-cols-3">
                            <div  v-for="(item,index) in conditions" :key="index">
                              <input type="checkbox" @focus="errorMedicalConditions = ''" v-model="form.medical_conditions" name="medical_conditions[]" :value="item[lang]" class="check-input"> {{item[lang]}}
                            </div>
                        </div>

                        <div class="error pl-1 text-xs text-red-500" v-text="errorMedicalConditions"></div>
                    </div>
                    <div class="form-group form-m-y">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold mb-2">4.-{{inputActivities.label[lang]}}</div>
                        <div class="flex items-center pl-4 justify-items-start text-blue-custom text-xs font-semibold">
                            <div class=" mr-7">
                              <input type="radio" @focus="errorActivities = '' " v-model="form.activities" name="activities" value="si" class="check-input"> {{yes[lang]}}
                            </div>
                            <div>
                              <input type="radio"  @focus="errorActivities = '' " v-model="form.activities" name="activities" value="no" class="check-input"> No
                            </div>
                        </div>

                        <div class="error pl-1 text-xs text-red-500" v-text="errorActivities"></div>
                    </div>
                    <div class="form-group form-m-y">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold mb-2">5.-{{inputDiet.label[lang]}}</div>
                        <div class="flex items-center pl-4 justify-items-start text-blue-custom text-xs font-semibold">
                            <div class=" mr-7">
                              <input type="radio" @focus="errorDiet = '' " v-model="form.diet" name="diet" value="si" class="check-input"> {{yes[lang]}}
                            </div>
                            <div>
                              <input type="radio"  @focus="errorDiet = '' " v-model="form.diet" name="diet" value="no" class="check-input"> No
                            </div>
                        </div>

                        <div class="error pl-1 text-xs text-red-500" v-text="errorDiet"></div>
                    </div>
                    <div class="form-group form-m-y">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold mb-2">6.-{{inputChangeStyles.label[lang]}}</div>
                        <div class="flex items-center pl-4 justify-items-start text-blue-custom text-xs font-semibold">
                            <div class=" mr-7">
                              <input type="radio" @focus="errorChangeStyles = ''" v-model="form.change_styles" name="change_styles" value="si" class="check-input"> {{yes[lang]}}
                            </div>
                            <div>
                              <input type="radio" @focus="errorChangeStyles = ''" v-model="form.change_styles" name="change_styles" value="no" class="check-input"> No
                            </div>
                        </div>

                        <div class="error pl-1 text-xs text-red-500" v-text="errorChangeStyles"></div>
                    </div>
                    <div class=" text-blue-custom text-xs font-semibold my-5" v-html="infoForm[lang]">
                    </div>
                    <div class="form-group my-2">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputName.label[lang]}}</div>
                        <input v-model="form.name" type="text" @focus="errorName = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2 md:w-2/3" name="name">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorName"></div>
                    </div>
                    <div class="form-group my-2">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputEmail.label[lang]}}</div>
                        <input v-model="form.email" type="email" @focus="errorEmail = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2 md:w-2/3" name="email">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorEmail"></div>
                    </div>
                    <div class="form-group my-2">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputPhone.label[lang]}}</div>
                        <input v-model="form.phone" type="text" @focus="errorPhone = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2 md:w-2/3" name="phone">
                        <div class="error pl-1 text-xs text-red-500" v-text="errorPhone"></div>
                    </div>
                    <div class="form-group  mt-8 mb-5">
                        <div class=" font-mulish text-blue-custom text-sm font-semibold">{{inputMessage.label[lang]}}</div>
                        <div class="md:flex md:justify-between">
                            <div class="md:w-2/3">
                                <textarea v-model="form.message" rows="5" @focus="errorMessage = ''" class="w-full bg-gray-custom-300 border border-blue-custom rounded-lg pl-2" name="message"></textarea>
                                <div class="error pl-1 text-xs text-red-500" v-text="errorMessage"></div>
                            </div>
                            <div class="flex justify-end items-end mt-6 md:w-1/3 md:justify-center">
                                <button
                                :disabled="sending"
                                class=" text-white bg-blue-custom py-1 px-5 font-roboto rounded-md text-sm md:max-h-7 md:w-1/2 md:mb-8"
                                v-bind:class="{ 'opacity-60': sending }"
                                >
                                    <svg v-if="sending" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white float-left" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span v-text="sending?button.labelSending[lang]:button.labelStatic[lang]"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
</template>
<script>
export default {
    data() {
        return {
            lang:document.documentElement.lang,
            form:{
                medical_conditions:[],
                activities:[],
                diet:[],
                change_styles:[],
                weight:"",
                height:"",
                name:"",
                email:"",
                phone:"",
                message:"",
            },
            conditions:[
                {
                    "es":"Diabetes",
                    "en":"Diabetes"
                },
                {
                    "es":"hipertensión",
                    "en":"hypertension"
                },
                {
                    "es":"reflujo gastroesofágico",
                    "en":"gastroesophageal reflux,"
                },
                {
                    "es":"daño en las articulaciones que soportan el peso del cuerpo (rodillas, tobillos, caderas o columna vertebral)",
                    "en":"damage to the weight-bearing joints (knees, ankles, hips or spine)"
                },
                {
                    "es":"colesterol elevado",
                    "en":"high cholesterol"
                },
                {
                    "es":"apnea del sueño (ronquido nocturno)",
                    "en":"sleep apnea (night snoring)"
                },
                {
                    "es":"infertilidad",
                    "en":"infertility"
                }
            ],
            sending:false,
            inputOverweight:{
                label:{
                    "es":"Tu IMC es de",
                    "en":"You BMI is"
                },
                errorMessage:{
                    "es":"Este campo es obligatorio",
                    "en":"This field is required"
                }
            },
            inputMedicalConditions:{
                label:{
                    "es":`¿Padece alguna de las siguientes condiciones?`,
                    "en":`Do you have any of the following conditions?`
                },
                errorMessage:{
                    "es":"Este campo es obligatorio",
                    "en":"This field is required"
                }
            },
            inputActivities:{
                label:{
                    "es":"¿Su peso le impide realizar actividades que usted disfruta? *",
                    "en":"Does your weight keep you from doing activities that you enjoy? *"
                },
                errorMessage:{
                    "es":"Este campo es obligatorio",
                    "en":"This field is required"
                }
            },
            inputDiet:{
                label:{
                    "es":"¿Haz realizado dietas formales para disminuir su peso? *",
                    "en":"Have you done formal diets to reduce your weight? *"
                },
                errorMessage:{
                    "es":"Este campo es obligatorio",
                    "en":"This field is required"
                }
            },
            inputChangeStyles:{
                label:{
                    "es":"¿Puede usted cumplir con cambios de estilo de vida y cambios dietéticos a largo plazo? *",
                    "en":"Can You Stick With Long-Term Lifestyle Changes And Diet Changes? *"
                },
                errorMessage:{
                    "es":"Este campo es obligatorio",
                    "en":"This field is required"
                }
            },
            inputWeight:{
                label:{
                    "es":"¿Cuál es su Peso en Kg? *",
                    "en":"What is your Weight in Lbs? *"
                },
                errorMessage:{
                    "es":"Favor de ingresar su peso",
                    "en":"Please enter your weight"
                }
            },
            inputHeight:{
                label:{
                    "es":"¿Cuál es su estatura en mts? *",
                    "en":"Which is your height in inches? *"
                },
                errorMessage:{
                    "es":"Favor de ingresar su altura",
                    "en":"Please enter your height"
                }
            },
            inputName:{
                label:{
                    "es":"Nombre",
                    "en":"Name"
                },
                errorMessage:{
                    "es":"Favor de ingresar su nombre",
                    "en":"Please enter your name"
                }
            },
            inputEmail:{
                label:{
                    "es":"Correo",
                    "en":"Email"
                },
                errorMessage:{
                    "es":"Favor de ingresar su correo",
                    "en":"Please enter your email"
                },
                errorFormatMessage:{
                    "es":"Favor de ingresar un correo válido",
                    "en":"Please enter a valid email"
                }
            },
            inputPhone:{
                label:{
                    "es":"Teléfono",
                    "en":"Phone"
                },
                errorMessage:{
                    "es":"Favor de ingresar su teléfono",
                    "en":"Please enter your phone"
                }
            },
            inputMessage:{
                label:{
                    "es":"Cuéntanos tu caso",
                    "en":"Tell us about your case"
                },
                errorMessage:{
                    "es":"Favor de ingresar el mensaje",
                    "en":"Please enter the message"
                }
            },
            infoForm:{
                "es":`Datos de contacto <br>
                      Por favor indique su información de contacto para recibir la valoración.`,
                "en":`Contact information <br>
                      Please provide your contact information to receive the rating.`
            },
            yes:{
                "es":"Si",
                "en":"Yes"
            },
            button:{
                labelStatic:{
                  "es":"Enviar",
                  'en':"Send"
                },
                labelSending:{
                  "es":"Enviando",
                  'en':"Sending"
                }

            },
            errorOverweight :"",
            errorMedicalConditions :"",
            errorActivities :"",
            errorChangeStyles :"",
            errorWeight:"",
            errorHeight:"",
            errorName:"",
            errorDiet:"",
            errorEmail:"",
            errorPhone:"",
            errorMessage:"",

        }
    },
    computed:{
        bmi:function(){
            if(this.form.height !="" && this.form.weight != ""){
                return this.lang == 'en'? this.calculateStandar():this.calculateMetric();
            }else{
                return "";
            }
        }
    },
    methods:{
        sendMessage:function(e){
            this.sending = true;
            const route = this.lang == 'en'?'/en/send-candidate':'/es/send-candidate';
            const form = new FormData(this.$refs.form_candidate)
            axios.post(route,form)
            .then( response =>{
                this.sending = false;
                this.clearForm();
                const url = window.location.origin + window.location.pathname;
                history.replaceState(null, "",url+'?form=candidato');
                this.$toast.open({
                    message:response.data.message,
                    type: "success",
                    duration: 5000,
                    position: "bottom",
                    dismissible: true
                });
            })
            .catch( error =>{
                if(error.response.status === 422 ){
                    const messages = error.response.data.errors;

                    this.errorOverweight = messages.overweight? messages.overweight[0]:"";
                    this.errorMedicalConditions = messages.medical_conditions? messages.medical_conditions[0]:"";
                    this.errorActivities = messages.activities? messages.activities[0]:"";
                    this.errorChangeStyles = messages.change_styles? messages.change_styles[0]:"";
                    this.errorDiet = messages.diet? messages.diet[0]:"";
                    this.errorWeight= messages.weight? messages.weight[0]:"";
                    this.errorHeight= messages.height? messages.height[0]:"";
                    this.errorName = messages.name? messages.name[0]:"";
                    this.errorEmail = messages.email? messages.email[0]:"";
                    this.errorPhone = messages.phone? messages.phone[0]:"";
                    this.errorMessage = messages.message? messages.message[0]:"";

                }else{
                    this.$toast.open({
                        message:this.lang === 'en'?'An unexpected error occurred':'Ocurrió un error inesperado',
                        type: "error",
                        duration: 5000,
                        position: "bottom",
                        dismissible: true
                    });
                }

                this.sending = false;
            })
        },
        clearForm:function(){
            this.form.medical_conditions = [];
            this.form.activities = [];
            this.form.change_styles = [];
            this.form.diet = [];
            this.form.weight = "",
            this.form.height = "",
            this.form.name    = "";
            this.form.email   = "";
            this.form.phone   = "";
            this.form.message = "";
        },
        calculateStandar: function(){
            const pounds = this.form.weight * 703;
            const inches = this.form.height * this.form.height;
            const total  = pounds/inches;

            return total.toFixed(2);
        },
        calculateMetric:function(){
            const height = this.form.height * this.form.height;
            const weight = this.form.weight;
            const total  = weight/height;

            return total.toFixed(2);
        },
    }
}
</script>
<style scoped>
 /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
