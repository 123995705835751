<template>
    <div>
        <input type="hidden" name="date" v-model="inputDate">
        <button type="button" ref="buttonCalendar" @click="()=> showModal = true" :class="['text-white  py-1  px-5 font-roboto rounded-md text-sm',dateSelected?'bg-green-400':'bg-blue-custom'] ">
          <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-alt" class="svg-inline--fa fa-calendar-alt float-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>
          <span v-if="!dateSelected">{{labelButton[lang]}}</span>
          <span v-else>{{textDate}}</span>
        </button>
        <div v-show="showModal" class="modal h-screen w-full fixed left-0 top-0 flex justify-center items-center  bg-blue-custom bg-opacity-95  modal-container">
            <!-- modal -->
            <div class="bg-white rounded shadow-lg  w-11/12 h-5/6">
                <!-- modal header -->
                <div class="border-b px-4 py-2 flex justify-between items-center">
                    <h3 class="font-semibold text-lg">{{labelTitle[lang]}}</h3>
                    <button type="button" class="text-black " @click="()=> showModal = false">&cross;</button>
                </div>
                <!-- modal body -->
                <div class="p-3">
                    <!-- Schedule -->
                    <div class=" h-96 overflow-auto ">
                      <carousel v-if="days.length == numberDays" :nav="false" :margin="5" :dots="false" :responsive="responsiveConfig">
                        <day-schedule
                            v-for="(day,index) in this.days"
                            :key="index"
                            :day="day"
                            v-on:appointment="setDate($event)"
                        />
                      </carousel>
                      <div class="flex items-center justify-center h-96" v-else>
                          <svg class="animate-spin -ml-1 mr-3 h-8 w-8 text-gray-custom-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <div class=" text-gray-custom-500 font-roboto text-xl">
                              {{labelLoadingBooking[lang]}}
                            </div>
                      </div>
                    </div>
                </div>
                <div class="flex justify-end items-center w-100 p-3">
                    <button type="button" v-if="dateSelected" class="bg-red-600 hover:bg-red-700 px-3 py-1 rounded text-white mr-1" @click="dateCancel">{{labelDateCancel[lang]}}</button>
                    <button type="button" class=" bg-blue-custom hover:bg-blue-400   px-3 py-1 rounded text-white ml-4 mr-1" @click="()=> showModal = false">{{labelClose[lang]}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DaySchedule from "./DaySchedule";
import carousel from 'vue-owl-carousel';
import {mapState,mapActions} from "vuex"
export default {
name:'Calendar',
components:{
    DaySchedule,
    carousel
},
data() {
    return {
        showModal:false,
        date:null,
        responsiveConfig:{
            0:{items:1},
            340:{items:2},
            600:{items:3},
            786:{items:4},
            1024:{items:6}
        },
        numberDays:7,
        lang:document.documentElement.lang,
        dateSelected:false,
        labelLoadingBooking:{
            "es":"Cargando horarios...",
            "en":"Loading schedules..."
        },
        labelButton:{
            "es":"Agendar",
            "en":"Schedule"
        },
        labelTitle:{
            "es":"Agenda una cita",
            "en":"Make an appointment"
        },
        labelClose:{
            "es":"Guardar",
            "en":"Save"
        },
        labelDateCancel:{
            "es":"Cancelar",
            "en":"Cancel"
        }
    }
},
computed:{
    ...mapState('day',["days"]),
    textDate:function(){
       const nameMonths = [
            {"es":"Ene","en":"Jan"},
            {"es":"Feb","en":"Feb"},
            {"es":"Mar","en":"Mar"},
            {"es":"Abr","en":"Apr"},
            {"es":"May","en":"May"},
            {"es":"Jun","en":"Jun"},
            {"es":"Jul","en":"Jul"},
            {"es":"Ago","en":"Aug"},
            {"es":"Sep","en":"Sep"},
            {"es":"Oct","en":"Oct"},
            {"es":"Nov","en":"Nov"},
            {"es":"Dic","en":"Dec"}
        ]

        return this.date? `${this.date.date.getDate()}-${nameMonths[this.date.date.getMonth()][this.lang]} ${this.date.time}`:'';
    },
    inputDate:function(){
        return this.date != null? `${this.date.date.getFullYear()}-${this.date.date.getMonth()+1}-${this.date.date.getDate()} ${this.date.time}:00`:null;
    }
},
mounted: async function () {

    await this.fillDays({numberDays:this.numberDays,lang:this.lang});

},
methods: {
    ...mapActions("day",["fillDays"]),
    setDate:function(date){
        this.days.map( item =>{
            item.schedules.map( schedule => schedule.selected = false)
        });
        this.date = date;
        this.dateSelected = true;
    },
    dateCancel:function(){
        this.days.map( item =>{
            item.schedules.map( schedule => schedule.selected = false)
        });
        this.dateSelected = false;
        this.date = null;
    },
    updateCalendar:function(){
        this.fillDays({numberDays:this.numberDays,lang:this.lang});
        this.dateSelected = false;
        this.date = null;
    },
    openCalendar:function(){
        this.$refs.buttonCalendar.click();
    }
},
}
</script>
<style scoped>
  .fa-calendar-alt{
      width: 14px;
      height: auto;
      margin-right:5px;
  }
  .modal-container{
      z-index: 99;
  }
</style>
