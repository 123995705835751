<template>
    <div class=" h-full ">
        <div class=" text-center">
            <div class="name font-semibold text-sm">
                {{day.dayName}}
            </div>
            <div class=" font-semibold text-xs">
                {{`${day.dayNumber}-${day.monthName}-${day.year}`}}
            </div>
            <div>

            </div>
        </div>
        <hour
            v-for="(item,index) in day.schedules"
            :key="index"
            :hour="item"
            v-on:selectAppointment="selectAppointment($event)"
        />
    </div>
</template>
<script>
import Hour from "./Hour";
export default {
    name:'DaySchedule',
    components:{
        Hour
    },
    props:['day'],
    data() {
        return {
            startSchedule:{
                hour:10,
                minutes:30
            },
            endSchedule:{
                hour:17,
                minutes:30
            },

        }
    },
    methods: {
        selectAppointment:function(hour){

            this.$emit('appointment',hour)
        }
    },
}
</script>
